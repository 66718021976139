import * as React from "react"

import Layout from "../../components/layout";
import StepOne from "../../components/step-1";

const GetOfferIndexPage = () => (
  <Layout title="get-offer">
    <StepOne title="get-offer" />
  </Layout>
)
 
export default GetOfferIndexPage